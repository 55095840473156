<template>
  <v-container
    fluid
  >
    <div
      class="d-flex mb-4 align-center justify-space-between"
    >
      <div>
        <h1
          v-translate
          translate-context="Page title"
          class="title font-weight-bold"
        >
          Project preconditions
        </h1>

        <p
          v-translate
          translate-context="Page content (project preconditions)"
          class="text--secondary body-2"
          style="max-width: 540px;"
        >
          Communicate effectively with your teams.
          With this list you can be sure that project preconditions are in check
          when work at the site commences.
        </p>
      </div>

      <v-menu
        offset-y
      >
        <template v-slot:activator="{ on, attrs, value: closed }">
          <v-btn
            x-small
            outlined
            class="pa-4"
            color="info darken-2"
            style="height: auto;"
            v-bind="attrs"
            v-on="on"
            @click="() => onTipMenuOpen(!closed)"
          >
            <v-icon
              large
              class="mr-3"
            >
              lightbulb
            </v-icon>

            <span
              v-html="startingAProjectTooltipOpenButtonContent"
            />

            <v-icon
              small
              class="ml-2"
            >
              keyboard_arrow_down
            </v-icon>
          </v-btn>
        </template>

        <v-card
          dark
          max-width="500px"
        >
          <v-card-subtitle
            v-translate
            translate-context="Starting a project tooltip [1/6]"
            class="font-weight-bold"
          >
            With these tips you will start a project smoothly
          </v-card-subtitle>

          <v-card-text>
            <ol
              class="precondition-tips"
            >
              <li
                v-translate
                translate-context="Starting a project tooltip [2/6]"
              >
                Appoint a foreman for a team
              </li>

              <li
                v-translate
                translate-context="Starting a project tooltip [3/6]"
              >
                Send a compiled scedule to the foreman through Mestamaster
              </li>

              <li>
                <span
                  v-translate
                  translate-context="Starting a project tooltip [4/6]"
                >
                  Involve the team with planning the schedule by asking for comments
                </span>

                <m-tip
                  :chip-text="startingAProjectTooltipDidYouKnowChipTitle"
                  :tip="startingAProjectTooltipDidYouKnowContent"
                  class="my-2"
                  chip-classes="font-weight-bold secondary darken-2"
                  text-classes="secondary--text text--lighten-3"
                />
              </li>

              <li
                v-translate
                translate-context="Starting a project tooltip [5/6]"
              >
                Make sure that the team's foreman has confirmed the schedule and required resources
              </li>

              <li
                v-translate
                translate-context="Starting a project tooltip [6/6]"
              >
                Appoint a responsible group lead who will answer for the team's tasks
              </li>
            </ol>
          </v-card-text>
        </v-card>
      </v-menu>
    </div>

    <v-menu
      v-model="historyMenu.show"
      :position-x="historyMenu.x"
      :position-y="historyMenu.y"
      absolute
      offset-y
      :close-on-content-click="false"
      :transition="false"
    >
      <v-card
        width="500"
      >
        <div
          class="backgroundAccent lighten-4 elevation-2 mb-1"
        >
          <v-card-title
            v-translate
            translate-context="Page content (project preconditions)"
          >
            Event history
          </v-card-title>

          <v-card-subtitle
            v-translate
            translate-context="Page content (project preconditions)"
          >
            Newest first
          </v-card-subtitle>
        </div>

        <v-card-text
          max-height="500"
          style="overflow-y: auto;"
          class="pt-0"
        >
          <v-list
            v-if="scheduleConfirmHistory.length > 0"
            dense
            two-line
            max-height="500"
            class="pt-0"
          >
            <template
              v-for="(history, i) in scheduleConfirmHistory"
            >
              <v-list-item
                :key="i"
                class="px-0"
              >
                <v-list-item-icon
                  class="caption-xs mr-0 mb-0"
                >
                  {{ scheduleConfirmHistory.length - i }}.
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ history.i18n }}

                    <v-tooltip
                      v-if="history.fileUrl"
                      top
                    >
                      <template #activator="{ on, attrs }">
                        <v-btn
                          icon
                          x-small
                          color="info darken-1"
                          class="ml-1"
                          v-bind="attrs"
                          v-on="on"
                          @click.stop.prevent="() => downloadSchedule(history)"
                        >
                          <v-icon
                            small
                          >
                            file_download
                          </v-icon>
                        </v-btn>
                      </template>

                      <span
                        v-translate
                        translate-context="Page content (project preconditions)"
                      >
                        Download schedule (.xlsx)
                      </span>
                    </v-tooltip>
                  </v-list-item-title>

                  <v-list-item-subtitle
                    class="caption-xs text-uppercase"
                  >
                    @ {{ history.actor.fullName }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                  <div
                    class="caption-xs text-right text--secondary"
                  >
                    <div
                      class="mb-n2"
                    >
                      {{ $dayjs(history.timestamp).fromNow() }}
                    </div>

                    <div>
                      {{ $dayjs(history.timestamp).format('DD.MM.YYYY HH:mm') }}
                    </div>
                  </div>
                </v-list-item-icon>
              </v-list-item>

              <v-divider
                :key="`${i}-divider`"
              />
            </template>
          </v-list>

          <div
            v-else
            v-translate
            translate-context="Page content (project preconditions)"
            class="pt-3 text-center"
          >
            No events
          </div>
        </v-card-text>
      </v-card>
    </v-menu>

    <v-menu
      v-model="scheduleMenu.show"
      :position-x="scheduleMenu.x"
      :position-y="scheduleMenu.y"
      absolute
      offset-y
      :close-on-content-click="false"
      :transition="false"
    >
      <v-card
        v-if="scheduleMenu.show"
        max-width="400px"
      >
        <v-card-title
          v-translate
          translate-context="Page content (project preconditions)"
        >
          Send schedule
        </v-card-title>

        <v-card-subtitle>
          <span
            v-translate
            translate-context="Page content (project preconditions) send schedule [1/6]"
          >
            Mestamaster compiles the schedule for team
          </span>
          <span class="font-italic font-weight-bold">
            {{ scheduleMenu.team.name }}
          </span>

          <span
            v-translate
            translate-context="Page content (project preconditions) send schedule [2/6]"
          >
            and emails it as an attachment.
          </span>
        </v-card-subtitle>

        <v-card-text>
          <v-alert
            class="caption mb-0"
            text
            color="secondary darken-2"
          >
            <span
              v-translate
              translate-context="Page content (project preconditions) send schedule [3/6]"
            >
              Scedule will be sent to
            </span>
            <span class="font-italic font-weight-bold">
              {{ scheduleMenu.team.sc.receiver.fullName }}
            </span>
            <span
              v-translate
              translate-context="Page content (project preconditions) send schedule [4/6]"
            >
              into email address
            </span>
            <span class="font-italic font-weight-bold">
              {{ scheduleMenu.team.sc.receiver.email }}
            </span>
          </v-alert>
        </v-card-text>

        <v-card-actions
          class="px-4 pb-4 flex-column align-start"
        >
          <v-btn
            :disabled="$wait.is('mailing schedule') || scheduleMenu.scheduleSent"
            :loading="$wait.is('mailing schedule')"
            small
            :dark="!$wait.is('mailing schedule') && !scheduleMenu.scheduleSent"
            color="secondary"
            class="px-6 py-4"
            @click="onSendSchedule"
          >
            <v-icon
              v-if="scheduleMenu.scheduleSent"
            >
              done
            </v-icon>

            <span
              v-else
              v-translate
              translate-context="Page content (project preconditions) send schedule [5/6]"
            >
              Send schedule
            </span>
          </v-btn>

          <div
            class="caption-xs mt-3 text--secondary"
          >
            <v-icon
              small
              left
              color="warning"
            >
              warning
            </v-icon>
            <span
              v-translate
              translate-context="Page content (project preconditions) send schedule [6/6]"
            >
              In rare cases the email is redirected to spam/trash.
            </span>
          </div>
        </v-card-actions>
      </v-card>
    </v-menu>

    <m-loader-circular
      v-if="loading"
      :text="fetchingPreconditionsLoaderContent"
    />

    <m-tip-note
      v-else-if="teams.length <= 0"
      :title="noTeamsTipTitle"
      :tip="noTeamsTipContent"
    />

    <v-card
      v-else
    >
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th
                v-translate
                translate-context="Page content (project preconditions) table header col 1"
              >
                Team
              </th>

              <th>
                <v-tooltip
                  bottom
                  content-class="pa-0"
                >
                  <template v-slot:activator="{ on: tooltip }">
                    <div
                      v-on="tooltip"
                    >
                      <span
                        v-translate
                        translate-context="Page content (project preconditions) table header col 2"
                      >
                        Foreman
                      </span>

                      <v-icon
                        small
                        color="info"
                      >
                        info
                      </v-icon>
                    </div>
                  </template>

                  <v-card
                    dark
                    max-width="300px"
                  >
                    <v-card-text
                      v-translate
                      translate-context="Page content (project preconditions) table tip col 2"
                    >
                      A foreman can either be a foreman for the main contractor
                      or any one of the appointed teams.
                    </v-card-text>
                  </v-card>
                </v-tooltip>
              </th>

              <th
                v-translate
                translate-context="Page content (project preconditions) table header col 3"
              >
                Schedule
              </th>

              <th>
                <v-tooltip
                  bottom
                  content-class="pa-0"
                >
                  <template v-slot:activator="{ on: tooltip }">
                    <div
                      v-on="tooltip"
                    >
                      <span
                        v-translate
                        translate-context="Page content (project preconditions) table header col 4 [1/2]"
                      >
                        Team has commented
                      </span>
                      <br>
                      <span
                        v-translate
                        translate-context="Page content (project preconditions) table header col 4 [2/2]"
                      >
                        on schedule
                      </span>

                      <v-icon
                        small
                        color="info"
                      >
                        info
                      </v-icon>
                    </div>
                  </template>

                  <v-card
                    dark
                    max-width="300px"
                  >
                    <v-card-text
                      v-translate
                      translate-context="Page content (project preconditions) table tip col 4"
                    >
                      Involving teams in project schedule planning has been
                      noted to significantly improve the chances
                      for a construction project to succeed.
                    </v-card-text>
                  </v-card>
                </v-tooltip>
              </th>

              <th
                v-translate
                translate-context="Page content (project preconditions) table header col 5"
              >
                Group lead
              </th>

              <th
                v-translate
                translate-context="Page content (project preconditions) table header col 6"
              >
                Events
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(team, i) in teams"
              :key="i"
            >
              <td>
                {{ team.name }}
              </td>

              <td
                style="min-width: 220px; width: 220px; max-width: 220px;"
              >
                <v-combobox
                  :value="leaders.find(s => s.id === team.sc.receiverId)"
                  :items="leaders.length > 0 ? leaders : [noForemenComboboxPlaceholder]"
                  item-text="fullName"
                  hide-details
                  outlined
                  dense
                  clearable
                  :menu-props="{ maxHeight: 500 }"
                  class="caption"
                  @change="v => onSubmanagerChange(v, team)"
                >
                  <template #prepend-item>
                    <v-list-item
                      class="mt-2 py-1"
                      three-line
                      target="_blank"
                      style="max-width: 340px;"
                      :to="{
                        name: 'invite-user'
                      }"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-translate
                          translate-context="Page content (project preconditions) table content col 2 combobox title"
                          class="error--text text--darken-2 font-weight-bold"
                        >
                          Can't find the person you are looking for?
                        </v-list-item-title>

                        <v-list-item-subtitle
                          style="-webkit-line-clamp: 4;"
                          class="mt-2"
                        >
                          <span
                            v-html="foremenComboboxSubtitle"
                          />
                          <v-icon
                            x-small
                            class="ml-1"
                          >
                            open_in_new
                          </v-icon>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider
                      class="my-3"
                    />
                  </template>

                  <template #item="{ item, on, attrs }">
                    <v-list-item
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.fullName }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                          {{ item.humanReadableRole }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
              </td>

              <td
                class="py-2"
              >
                <div
                  class="d-flex"
                >
                  <v-btn
                    :disabled="!team.sc.receiverId"
                    x-small
                    :depressed="!!team.sc.receiverId"
                    :text="!team.sc.receiverId"
                    color="secondary lighten-3"
                    class="secondary--text text--darken-3"
                    style="height: auto;"
                    @click="e => openScheduleMenu(e, team)"
                  >
                    <template
                      v-if="team.sc.receiverId"
                    >
                      <span>
                        <span
                          v-translate
                          translate-context="Page content (project preconditions) table content col 3 [1/2]"
                        >
                          Send
                        </span>
                        <br>
                        <span
                          v-translate
                          translate-context="Page content (project preconditions) table content col 3 [2/2]"
                        >
                          schedule
                        </span>
                      </span>

                      <v-icon
                        small
                        class="ml-1"
                        style="pointer-events: none;"
                      >
                        keyboard_arrow_down
                      </v-icon>
                    </template>

                    <template
                      v-else
                    >
                      <span
                        v-translate
                        translate-context="Page content (project preconditions) table content col 3"
                      >
                        No foreman
                      </span>
                    </template>
                  </v-btn>

                  <div
                    v-if="team.sc.receiverId"
                    class="caption-xs text-uppercase ml-2"
                  >
                    <div
                      class="d-flex align-center white-space-nowrap"
                    >
                      <v-icon
                        :color="!!team.sc.sentAt || scheduleSent(team.id) ? 'success' : 'error'"
                        small
                        left
                      >
                        {{ !!team.sc.sentAt || scheduleSent(team.id) ? 'done' : 'close' }}
                      </v-icon>

                      <div>
                        <span
                          v-translate
                          translate-context="Page content (project preconditions) table content col 3"
                        >
                          Sent
                        </span>

                        <template
                          v-if="!!team.sc.sentAt"
                        >
                          {{ $dayjs(team.sc.sentAt).format('DD.MM.YYYY @ HH:mm') }}
                        </template>
                      </div>
                    </div>

                    <!--<div-->
                    <!--  class="d-flex align-center"-->
                    <!--&gt;-->
                    <!--  <v-icon-->
                    <!--    :color="!!team.sc.seenAt ? 'success' : 'error'"-->
                    <!--    small-->
                    <!--    left-->
                    <!--  >-->
                    <!--    {{ !!team.sc.seenAt ? 'done' : 'close' }}-->
                    <!--  </v-icon>-->

                    <!--  <div>-->
                    <!--    Nähnyt-->
                    <!--  </div>-->
                    <!--</div>-->

                    <div
                      class="d-flex align-center white-space-nowrap"
                    >
                      <v-icon
                        :color="!!team.sc.scheduleConfirmedAt ? 'success' : 'error'"
                        small
                        left
                      >
                        {{ !!team.sc.scheduleConfirmedAt ? 'done' : 'close' }}
                      </v-icon>

                      <div>
                        <span
                          v-translate
                          translate-context="Page content (project preconditions) table content col 3"
                        >
                          Approved
                        </span>

                        <template
                          v-if="!!team.sc.scheduleConfirmedAt"
                        >
                          {{ $dayjs(team.sc.scheduleConfirmedAt).format('DD.MM.YYYY @ HH:mm') }}
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </td>

              <td
                style="min-width: 190px;"
              >
                <v-checkbox
                  :disabled="!team.sc.receiverId
                    || $wait.is(`updating schedule commented at - ${team.sc.id}`)"
                  :input-value="!!team.sc.scheduleCommentedAt"
                  hide-details
                  class="my-2"
                  color="secondary"
                  @change="v => onCommentedChange(v, team.sc)"
                >
                  <template #label>
                    <div
                      class="caption d-flex justify-center align-center"
                    >
                      <span
                        v-translate
                        translate-context="Page content (project preconditions) table content col 4"
                      >
                        Commented
                      </span>

                      <v-progress-circular
                        v-show="$wait.is(`updating schedule commented at - ${team.sc.id}`)"
                        :size="12"
                        :width="1"
                        color="secondary"
                        indeterminate
                        class="ml-3"
                      />
                    </div>
                  </template>
                </v-checkbox>
              </td>

              <td
                style="min-width: 220px; width: 220px; max-width: 220px;"
              >
                <v-combobox
                  :disabled="!team.sc.receiverId"
                  :value="noses.find(s => s.id === team.sc.noseId)"
                  :items="noses.length > 0 ? noses : [noNosesComboboxPlaceholder]"
                  item-text="fullName"
                  hide-details
                  outlined
                  dense
                  clearable
                  :menu-props="{ maxHeight: 500 }"
                  class="caption"
                  :style="{
                    opacity: !!team.sc.receiverId ? 1 : 0.5,
                  }"
                  @change="v => onNoseChange(v, team.sc)"
                >
                  <template #prepend-item>
                    <v-list-item
                      class="mt-2"
                      three-line
                      target="_blank"
                      style="max-width: 360px;"
                      :to="{
                        name: 'invite-user'
                      }"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-translate
                          translate-context="Page content (project preconditions) table content col 5 combobox title"
                          class="error--text text--darken-2 font-weight-bold"
                        >
                          Can't find the person you are looking for?
                        </v-list-item-title>

                        <v-list-item-subtitle>
                          <span
                            v-html="nosesComboboxSubtitle"
                          />
                          <v-icon
                            x-small
                            class="ml-1"
                          >
                            open_in_new
                          </v-icon>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider
                      class="my-3"
                    />
                  </template>
                </v-combobox>
              </td>

              <td
                class="text-right"
              >
                <v-btn
                  :disabled="$wait.is(`loading history ${team.id}`)"
                  :loading="$wait.is(`loading history ${team.id}`)"
                  icon
                  @click="e => onHistoryMenuOpen(e, team)"
                >
                  <v-icon
                    small
                  >
                    timeline
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <!-- <m-review-snackbar
      cookie-id="rate-project-preconditions-v1"
      mixpanel-event="Rate Project Preconditions V1"
      :title="reviewSnackbarTitle()"
      :min-title="reviewSnackbarMinTitle()"
      :max-title="reviewSnackbarMaxTitle()"
    /> -->
  </v-container>
</template>

<script>
  import { mapWaitingActions } from 'vue-wait';
  import { mapGetters } from 'vuex';
  import MReviewSnackbar from '@/components/MReviewSnackbar';

  export default {
    components: { MReviewSnackbar },
    props: {
      projectId: {
        type: [Number, String],
        required: true,
      },
    },

    data: () => ({
      sentTo: [],
      scheduleMenu: {
        x: null,
        y: null,
        show: false,
        team: null,
        scheduleSent: false,
      },
      historyMenu: {
        x: null,
        y: null,
        show: false,
        teamId: null,
      },
    }),

    computed: {
      ...mapGetters({
        __teams__: 'project/teams/teams',
        __scheduleConfirms__: 'project/scheduleConfirms/scheduleConfirms',
        scheduleConfirmHistory: 'project/scheduleConfirms/scheduleConfirmHistory',
        managers: 'users/managers',
        submanagers: 'users/submanagers',
        noses: 'users/noses',
      }),

      loading() {
        return this.$wait.is([
          'loading data',
          'loading managers',
          'loading submanagers',
          'loading noses',
        ]);
      },

      leaders() {
        return [
          ...this.managers,
          ...this.submanagers,
        ];
      },

      teams() {
        const teams = this.__teams__.map(team => ({
          ...team,
          sc: this.scheduleConfirms.find(sc => sc.teamId === team.id) || {},
        }));

        return teams;
      },

      scheduleConfirms() {
        const confirms = this.__scheduleConfirms__.map(scheduleConfirm => ({
          ...scheduleConfirm,
          nose: this.noses.find(s => s.id === scheduleConfirm.noseId) || {},
          receiver: this.leaders.find(
            s => s.id === scheduleConfirm.receiverId,
          ) || {},
        }));

        return confirms;
      },

      defaultMixpanelAttrs() {
        return {
          projectId: this.$projectId,
        };
      },

      startingAProjectTooltipOpenButtonContent() {
        return `
          ${this.$pgettext('Starting a project tooltip open button [1/3]', 'Tips for')}
          <br>
          ${this.$pgettext('Starting a project tooltip open button [2/3]', 'starting')}
          <br>
          ${this.$pgettext('Starting a project tooltip open button [3/3]', 'a project')}
        `;
      },

      startingAProjectTooltipDidYouKnowChipTitle() {
        return this.$pgettext('Starting a project tooltip chip title', 'Did you know?');
      },

      startingAProjectTooltipDidYouKnowContent() {
        return this.$pgettext(
          'Starting a project tooltip chip content',
          '82 % of subcontractors have estimated that better communication with the main contractor would have reduced the amount of problems encountered during a project.',
        );
      },

      fetchingPreconditionsLoaderContent() {
        return this.$pgettext(
          'Loader',
          'Fetching preconditions...',
        );
      },

      noTeamsTipTitle() {
        return this.$pgettext(
          'Page content (project preconditions) no teams tip title',
          'No teams have been added to this project.',
        );
      },

      noTeamsTipContent() {
        return this.$pgettext(
          'Page content (project preconditions) no teams tip content',
          'Teams can be added through project planning.',
        );
      },

      noForemenComboboxPlaceholder() {
        return this.$pgettext(
          'Combobox placeholder',
          'No foremen found',
        );
      },

      noNosesComboboxPlaceholder() {
        return this.$pgettext(
          'Combobox placeholder',
          'No group leads found',
        );
      },

      foremenComboboxSubtitle() {
        return this.$pgettext(
          'Page content (project preconditions) table content col 2 combobox subtitle',
          `
          Make sure that the user's role is set either to
          <b>Foreman</b> or <b>Foreman, Subcontractor</b>.
          A new user can be invited here
          `,
        );
      },

      nosesComboboxSubtitle() {
        return this.$pgettext(
          'Page content (project preconditions) table content col 5 combobox subtitle',
          `
          Make sure that the user's role is set to
          <b>Group lead, Subcontractor</b>.
          A new user can be invited here
          `,
        );
      },
    },

    async created() {
      await Promise.all([
        this.loadScheduleConfirms(),
        this.loadTeams(),
        this.loadManagers({ scope: 'managers' }),
        this.loadSubmanagers({ scope: 'submanagers' }),
        this.loadNoses({ scope: 'noses' }),
      ]);

      this.$nextTick(() => {
        this.$mixpanel.trackEvent('Prepare Project Preconditions', {
          ...this.defaultMixpanelAttrs,
          step: 'Open the View',
          teamsCount: this.teams.length,
        });
      });
    },

    methods: {
      ...mapWaitingActions('project/teams', {
        loadTeams: 'loading data',
      }),

      ...mapWaitingActions('project/scheduleConfirms', {
        loadScheduleConfirms: 'loading data',
        createScheduleConfirm: 'creating schedule confirm',
        updateScheduleConfirm: 'updating schedule confirm',
        destroyScheduleConfirm: 'destroying schedule confirm',
        mailScheduleToManager: 'mailing schedule',
        loadScheduleConfirmHistoryByTeam: 'loading schedule confirm history by team',
      }),

      ...mapWaitingActions('users', {
        loadManagers: {
          action: 'loadUsers',
          loader: 'loading managers',
        },
        loadSubmanagers: {
          action: 'loadUsers',
          loader: 'loading submanagers',
        },
        loadNoses: {
          action: 'loadUsers',
          loader: 'loading noses',
        },
      }),

      openScheduleMenu(e, team) {
        const {
          bottom: y,
          left: x,
        } = e.target.parentNode.getBoundingClientRect(); // parentNode is the actual HTML button

        if (this.scheduleMenu.show) {
          this.scheduleMenu.show = false;
          this.scheduleMenu.x = null;
          this.scheduleMenu.y = null;
          this.scheduleMenu.team = null;
        } else {
          this.scheduleMenu.scheduleSent = false;
          this.scheduleMenu.x = x;
          this.scheduleMenu.y = y;
          this.scheduleMenu.team = team;
          this.scheduleMenu.show = true;

          this.$mixpanel.trackEvent('Prepare Project Preconditions', {
            ...this.defaultMixpanelAttrs,
            step: 'Open Mail Schedule Menu',
            teamId: team.id,
          });
        }
      },

      async onSubmanagerChange(submanager, team) {
        if (team.sc.id) {
          await this.destroyScheduleConfirm({
            id: team.sc.id,
          });

          // Resets the sentTo icon state
          const i = this.sentTo.findIndex(teamId => teamId === this.scheduleMenu.team.id);
          if (i >= 0) this.sentTo.splice(i, 1);
        }

        if (submanager) {
          await this.createScheduleConfirm({
            scheduleConfirm: {
              receiverId: submanager.id,
              teamId: team.id,
            },
          });

          this.$mixpanel.trackEvent('Prepare Project Preconditions', {
            ...this.defaultMixpanelAttrs,
            step: 'Name Submanager',
            teamId: team.id,
          });
        } else {
          this.$mixpanel.trackEvent('Prepare Project Preconditions', {
            ...this.defaultMixpanelAttrs,
            step: 'Clear Submanager',
            teamId: team.id,
          });
        }
      },

      async onNoseChange(nose, { id: scId, teamId }) {
        if (!scId) return;

        await this.updateScheduleConfirm({
          id: scId,
          payload: {
            scheduleConfirm: {
              noseId: nose ? nose.id : null,
            },
          },
        });

        this.$mixpanel.trackEvent('Prepare Project Preconditions', {
          ...this.defaultMixpanelAttrs,
          step: nose ? 'Name Nose' : 'Clear Nose',
          teamId,
        });
      },

      async onSendSchedule() {
        await this.mailScheduleToManager({
          scheduleConfirmId: this.scheduleMenu.team.sc.id,
        });

        this.scheduleMenu.scheduleSent = true;

        this.$mixpanel.trackEvent('Prepare Project Preconditions', {
          ...this.defaultMixpanelAttrs,
          step: 'Mail Schedule',
          teamId: this.scheduleMenu.team.id,
        });

        this.sentTo.push(this.scheduleMenu.team.id);
      },

      async onCommentedChange(checked, { id: scId, teamId }) {
        const str = `updating schedule commented at - ${scId}`;
        this.$wait.start(str);

        await this.updateScheduleConfirm({
          id: scId,
          payload: {
            scheduleConfirm: {
              commented: checked,
            },
          },
        });

        this.$wait.end(str);

        this.$mixpanel.trackEvent('Prepare Project Preconditions', {
          ...this.defaultMixpanelAttrs,
          step: 'Schedule Commented',
          teamId,
        });
      },

      onTipMenuOpen(opened) {
        if (opened) {
          this.$mixpanel.trackEvent('Prepare Project Preconditions', {
            ...this.defaultMixpanelAttrs,
            step: 'Open Tip Menu',
          });
        }
      },

      scheduleSent(teamId) {
        return this.sentTo.includes(teamId);
      },

      async onHistoryMenuOpen(e, { id: teamId }) {
        this.$wait.start(`loading history ${teamId}`);

        const {
          bottom: y,
          left: x,
        } = e.target.parentNode.getBoundingClientRect(); // parentNode is the actual HTML button

        await this.loadScheduleConfirmHistoryByTeam({ teamId });

        this.historyMenu.x = x;
        this.historyMenu.y = y;
        this.historyMenu.show = true;
        this.historyMenu.teamId = teamId;
        this.$wait.end(`loading history ${teamId}`);

        this.$mixpanel.trackEvent('Prepare Project Preconditions', {
          ...this.defaultMixpanelAttrs,
          step: 'Open History Menu',
          teamId,
        });
      },

      downloadSchedule({ fileUrl }) {
        this.$mixpanel.trackEvent('Prepare Project Preconditions', {
          ...this.defaultMixpanelAttrs,
          step: 'Download Schedule',
          teamId: this.historyMenu.teamId,
        });

        window.open(fileUrl);
      },

      reviewSnackbarTitle() {
        return this.$pgettext(
          'Review snackbar title',
          'Please estimate how useful this view is for you.',
        );
      },

      reviewSnackbarMinTitle() {
        return this.$pgettext(
          'Review snackbar min-title',
          'No need',
        );
      },

      reviewSnackbarMaxTitle() {
        return this.$pgettext(
          'Review snackbar max-title',
          'Useful',
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .precondition-tips li {
    margin-bottom: 12px;
  }
</style>
