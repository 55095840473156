<template>
  <v-snackbar
    :value="show && !dismissed"
    timeout="-1"
    bottom
    right
    content-class="pa-0"
  >
    <v-card
      flat
      color="transparent"
      max-width="400px"
    >
      <v-card-text
        class="pa-6"
      >
        <div
          class="mb-5 d-flex justify-space-between align-center"
        >
          <div>
            {{ title }}
          </div>

          <div>
            <v-btn
              icon
              color="backgroundAccent"
            >
              <v-icon
                small
                @click="dismissed = true"
              >
                close
              </v-icon>
            </v-btn>
          </div>
        </div>

        <div>
          <v-rating
            v-model="rating"
            hover
            :readonly="ratingSubmitted"
            color="deep-orange"
            background-color="deep-orange"
            length="5"
          />

          <div
            class="d-flex caption-xs"
          >
            <div>
              {{ computedMinTitle }}
            </div>

            <div
              class="ml-8"
            >
              {{ computedNeutralTitle }}
            </div>

            <div
              class="ml-10"
            >
              {{ computedMaxTitle }}
            </div>
          </div>

          <v-btn
            class="mt-8 px-8"
            color="deep-orange darken-2"
            depressed
            :dark="rating > 0 && !ratingSubmitted"
            :disabled="rating <= 0 || ratingSubmitted || $wait.is('submitting rating')"
            :loading="$wait.is('submitting rating')"
            @click="submitRating"
          >
            {{ submitRatingButtonContent }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-snackbar>
</template>

<script>
  import { getCookie, setCookie } from '@/vuex/utils/cookie';

  export default {
    props: {
      title: {
        type: String,
        required: true,
      },

      mixpanelEvent: {
        type: String,
        required: true,
      },

      mixpanelEventParams: {
        type: Object,
        default: () => ({}),
      },

      cookieId: {
        type: String,
        required: true,
      },

      minTitle: {
        type: String,
        default: null,
      },

      neutralTitle: {
        type: String,
        default: null,
      },

      maxTitle: {
        type: String,
        default: null,
      },
    },

    data: () => ({
      rating: null,
      ratingSubmitted: false,
      didRate: false,
      visits: 0,
      dismissed: false,
      FEATURE_REVIEWS: 'FEATURE_REVIEWS',
    }),

    computed: {
      show() {
        return this.visits >= 3 && !this.dismissed;
      },

      defaultCookie() {
        return {
          didRate: false,
          visits: 0,
        };
      },

      cookie() {
        let cookieStorage = JSON.parse(getCookie(this.FEATURE_REVIEWS) || null);

        if (!cookieStorage) {
          setCookie(this.FEATURE_REVIEWS, JSON.stringify({}));
          cookieStorage = JSON.parse(getCookie(this.FEATURE_REVIEWS));
        }

        if (!cookieStorage[this.cookieId]) {
          cookieStorage = {
            ...cookieStorage,
            [this.cookieId]: this.defaultCookie,
          };
          setCookie(this.FEATURE_REVIEWS, JSON.stringify(cookieStorage));
        }

        return cookieStorage[this.cookieId];
      },

      computedMinTitle() {
        if (!this.minTitle) {
          return this.$pgettext('MReviewSnackbar component default min-title', 'Worse');
        }

        return this.minTitle;
      },

      computedNeutralTitle() {
        if (!this.neutralTitle) {
          return this.$pgettext('MReviewSnackbar component default neutral-title', 'Neutral');
        }

        return this.neutralTitle;
      },

      computedMaxTitle() {
        if (!this.maxTitle) {
          return this.$pgettext('MReviewSnackbar component default max-title', 'Better');
        }

        return this.maxTitle;
      },

      submitRatingButtonContent() {
        if (this.ratingSubmitted) {
          return this.$pgettext('MReviewSnackbar component submit rating button', 'Thanks for your feedback!');
        }

        return this.$pgettext('MReviewSnackbar component submit rating button', 'Send');
      },
    },

    watch: {
      didRate(rate) {
        if (!rate) return;
        const { cookie } = this;
        cookie.didRate = true;
        this.saveCookie(cookie);
      },

      visits(visit) {
        const { cookie } = this;
        cookie.visits = visit;
        this.saveCookie(cookie);
      },
    },

    mounted() {
      const { cookie } = this;

      this.didRate = cookie.didRate;
      this.dismissed = this.didRate;

      /**
       * Don't count to the infinity.
       */
      if (cookie.visits < 3) {
        this.visits = cookie.visits + 1;
      } else {
        this.visits = cookie.visits;
      }
    },

    methods: {
      saveCookie(cookie) {
        const cookieStorage = JSON.parse(getCookie(this.FEATURE_REVIEWS));
        const data = {
          ...cookieStorage,
          [this.cookieId]: cookie,
        };
        setCookie(this.FEATURE_REVIEWS, JSON.stringify(data));
      },

      submitRating() {
        this.$wait.start('submitting rating');

        this.$mixpanel.trackEvent(this.mixpanelEvent, {
          rating: this.rating,
          ...this.mixpanelEventParams,
        });

        this.$wait.end('submitting rating');

        this.ratingSubmitted = true;
        this.didRate = true;
      },
    },
  };
</script>
